import { Injectable } from "@angular/core";
import moment from "moment";
import { Geolocation, Geoposition } from "@ionic-native/geolocation/ngx";
import { Subscription } from "rxjs";
import { Storage } from "@ionic/storage";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class DistanceUtilsService {
  userLocation: any;
  constructor(private geolocation: Geolocation, private storage: Storage) {
    this.userLocation = this.getCurrentLocation();
  }

  async calculateDistance(
    lat1: number,
    lon1: number,
    unit: "miles" | "kilometers" | "meters" = "kilometers"
  ) {
    try {
      if (!this.userLocation) this.userLocation = await this.getCurrentLocation();
      // this.userLocation = { latitude: 41.1082021, longitude: -75.4554563 };
      let radius: number;

      // Define el radio según la unidad de medida
      if (unit === "miles") {
        radius = 3958.8; // Earth's radius in miles
      } else if (unit === "kilometers") {
        radius = 6371; // Earth's radius in kilometers
      } else if (unit === "meters") {
        radius = 6371000; // Earth's radius in meters
      } else {
        throw new Error("Unidad de medida no válida");
      }

      const toRadians = (degrees: number) => {
        return degrees * (Math.PI / 180);
      };

      const dLat = toRadians(lat1 - this.userLocation.latitude);
      const dLon = toRadians(lon1 - this.userLocation.longitude);

      const lat1Rad = toRadians(this.userLocation.latitude);
      const lat2Rad = toRadians(lat1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = radius * c;

      return parseFloat(distance.toFixed(2));
    } catch (error) {
      console.log(error);
    }
  }

  async calculateDistanceRoute(
    lat1: number,
    lon1: number,
    unit: "miles" | "kilometers" | "meters" = "kilometers"
  ) {
    try {
      // this.userLocation = { latitude: 41.1082021, longitude: -75.4554563 };
      const route: any = await this.calculateRoute(lat1, lon1)
      // console.log(route)
      const distanceInMeters = route.routes[0].legs[0].distance.value;
          let distance;

          switch (unit) {
            case "meters":
              distance = distanceInMeters;
              break;
            case "kilometers":
              distance = distanceInMeters / 1000;
              break;
            case "miles":
              distance = distanceInMeters / 1609.34;
              break;
            default:
              distance = distanceInMeters;
          }

          route.routes[0].legs[0].distance.text = `${distance.toFixed(2)} miles`;
          return route;
    } catch (error) {
      console.log(error);
    }
  }

  async calculateRoute(lat, long) {
    const directionsService = new google.maps.DirectionsService();
    const origin = new google.maps.LatLng(this.userLocation.latitude, this.userLocation.longitude);
    const destination = new google.maps.LatLng(lat, long);

    const request = {
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    return directionsService.route(request, (result, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
      const route = result.routes[0];
      const leg = route.legs[0];

      // Distancia y duración de la ruta más óptima
      const distance = leg.distance.text; // Ej: "13.7 km"
      const duration = leg.duration.text; // Ej: "20 mins"

      return { distance, duration };
      } else {
        console.error("Error al calcular la ruta: " + status);
      }
    });
  }


  async getCurrentLocation() {
    console.log("getCurrentLocation", moment().toDate());
    try {
      this.geolocation
        .watchPosition({ enableHighAccuracy: true, timeout: 9000 })
        .subscribe(async (location: any) => {
          // console.log(location);
          if (location.code == 3 || location.code == 2) {
            const lastLocation = await this.storage.get("lastLocation");
            this.userLocation = lastLocation;
          } else {
            this.userLocation = {
              latitude: location.coords.latitude,
              longitude: location.coords.longitude,
            };
            this.storage.set("lastLocation", this.userLocation);
          }

          return this.userLocation;
        }),
        async (error) => {
          console.error("Error getting location", error);
          const lastLocation = await this.storage.get("lastLocation");
          this.userLocation = lastLocation;
          return this.userLocation;
        };
    } catch (error) {
      throw error;
    }
  }
}
