import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SubCategory } from '@app/core/data/SubCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { environment } from '@env/environment';
import _, { map } from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { IFrameModalPage } from '@app/i-frame-modal/i-frame-modal.page';
declare function require(name:string);

@Component({
  selector: "app-categories-list",
  templateUrl: require("template/" +
    environment.template +
    "/html/categories-list.component.html"),
  styles: [
    require("template/" +
      environment.template +
      "/styles/categories-list.component.scss"),
  ],
})
export class CategoriesListComponent implements OnInit {
  public environment = environment;
  @Input()
  deepLink?: string;
  @Input() subcategories: any;
  categoryId?: number;
  categories: any[];
  isLoading: boolean;
  @Input() parentPageId?: any;
  title: string;
  private SIOUX_FALLS_PARK_LISTING_ID = 4500;
  language: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private modalCtrlr: ModalController,
    private dataController: CMSDataControllerService
  ) {}

  ngOnInit() {
    this.language = this.translate.currentLang;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.queryParams.subscribe(params => {
      console.log(params)
      this.deepLink =
        params.rssLink ||
        params.deepLink;
      this.title = params.t;
      this.parentPageId = params.parentPageId;
      console.log(new Date() + ">>> " + this.deepLink, this.title);
      if (!this.deepLink) {
        //ToDo section in BigBear.
        this.deepLink = "category://?categoryid=28";
      }
      if (this.parentPageId){

        this.dataController.getPages({ domainId:  environment.cms.domainId, parentPageId: this.parentPageId }, this.language).subscribe(pages => {
            this.subcategories = pages.filter(page => page.active);

            console.log(new Date() + 'loading Complete');
            this.isLoading = false;
        }, err => {
          console.error(err);
          this.isLoading = false;
        });
      }else if (this.deepLink) {
        // get category id from the url
        const parsedUrl = new URL(this.deepLink);
        let categoryIdKey: string;
        parsedUrl.searchParams.forEach((value, key) => {
          if ("categoryid" === key.toLocaleLowerCase()) {
            categoryIdKey = key;
          }
        });
        //NORMAL CODE
        // const categoryId = parsedUrl.searchParams.get(categoryIdKey);
        //NORMAL CODE END

        //HARCODE FOR SHOP SECTION BIGBEAR
        var categoryId: any;
        if (parsedUrl.searchParams.getAll(categoryIdKey).length > 1) {
          categoryId = parsedUrl.searchParams.getAll(categoryIdKey);
        } else {
          categoryId = parsedUrl.searchParams.get(categoryIdKey);
        }
        //HARCODE FOR SHOP SECTION BIGBEAR END
        console.log(
          new Date() + ">>> [" + categoryIdKey + ", " + categoryId + "]"
        );
        if (categoryId) {
          this.isLoading = true;
          // var categories = sessionStorage.getItem("categories_all");
          // if (categories != null && categories.length > 0) {
          //   this.subcategories = JSON.parse(categories);
          // }
          // else {
          // }

          // get subcategories given category id
          this.dataController
            .getListingsSubCat({
              domainId: environment.cms.domainId,
              dataSyndication: false,
            })
            .subscribe(
              (list) => {
                this.subcategories = list
                  .filter((item) => {
                    if (item.CategoryID && item.SubCategoryID) {
                      if (item.SubCategoryName)
                        item.SubCategoryName = item.SubCategoryName.replace(
                          /\//g,
                          " / "
                        );
                      //NORMAL CODE
                      // return +categoryId === item.CategoryID;
                      //NORMAL CODE END

                      //HARCODE FOR SHOP SECTION BIGBEAR
                      if (typeof JSON.parse(categoryId) == "object") {
                        console.log(
                          categoryId.includes(item.CategoryID.toString())
                        );
                        return categoryId.includes(item.CategoryID.toString());
                      } else {
                        return +categoryId === item.CategoryID;
                      }
                      //HARCODE FOR SHOP SECTION BIGBEAR END
                    }
                  })
                  .sort((a, b) =>
                    a.SubCategoryName.localeCompare(b.SubCategoryName)
                  );
                // console.log(new Date() + ':getListings...');
                // this.dataController.getListings({ domainId:  environment.cms.domainId, dataSyndication: false })
                // .subscribe(list => {
                //   this.subcategories.forEach(cat => {
                //     var filtered = list.filter(item => item.SubCategoryID == cat.SubCategoryID && item.Image_List);
                //     if (filtered.length > 0 && filtered[0].Image_List) {
                //       cat.Image = filtered[0].Image_List.split('|')[0];
                //     }
                //     });
                //   this.isLoading = false;
                //   //console.log(new Date() + 'loading Complete');
                //   //console.log(new Date() + ':' + list.length);
                // });
                console.log(this.subcategories);
                this.dataController
                  .getPages({ domainId: environment.cms.domainId }, this.language)
                  .subscribe(
                    (pages) => {
                      const parentPage = pages.find((it) => {
                        return (
                          it.active && it.rssLink && it.rssLink === this.deepLink
                        );
                      });
                      console.log(parentPage);
                      if (parentPage) {
                        const catPages = pages.filter((item) => {
                          return (
                            item.active && parentPage.PageID === item.ParentPageID
                          );
                        });
                        console.log(catPages);
                        const subcatsToImages = new Map<number, string>();
                        catPages.forEach((page) => {
                          if (
                            page.rssLink &&
                            page.ButtonImage &&
                            page.rssLink.includes("://")
                          ) {
                            const url = new URL(page.rssLink);
                            let subCategoryIdKey: string;
                            url.searchParams.forEach((value, key) => {
                              if ("subcategoryid" === key.toLocaleLowerCase()) {
                                subCategoryIdKey = key;
                              }
                            });
                            const subCategoryId =
                              url.searchParams.get(subCategoryIdKey);
                            if (subCategoryId) {
                              subcatsToImages.set(
                                +subCategoryId,
                                page.ButtonImage
                              );
                            }
                          }
                        });
                        // bind image to each subcategory
                        this.subcategories.forEach((cat) => {
                          if (subcatsToImages.has(cat.SubCategoryID)) {
                            cat.Image = subcatsToImages.get(cat.SubCategoryID);
                          } else {
                          }
                        });
                        if (catPages.length) this.subcategories = catPages;
                        console.log(new Date() + "loading Complete");
                        this.isLoading = false;
                      } else {
                        this.isLoading = false;
                      }
                    },
                    (err) => {
                      console.error(err);
                      this.isLoading = false;
                    }
                  );
              },
              (err) => {
                console.error(err);
                this.isLoading = false;
                console.log(new Date() + "loading Complete");
              }
            );
        }
      }
    })
  }

  ionViewDidLeave() {
    this.subcategories = null;
    console.log("destroy");
  }

  // ionViewWillEnter(){
  //   debugger;
  //   let currentUrl = this.router.url;
  //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //   this.router.onSameUrlNavigation = 'reload';
  //   this.router.navigate([currentUrl]);
  //   this.ngOnInit();
  //   console.log("ionViewWillEnter")
  // }

  onItemClick(item: any) {
    console.log(item);
    if (
      item.PageID == 8963 ||
      item.PageID == 4400 ||
      item.PageID == 4394 ||
      item.rssLink.includes("content://")
    ) {
      this.router.navigate(["/tabs/to-do"], {
        queryParams: {
          t: item.PageName,
          rssLink: item.rssLink,
          parentPageId: item.PageID,
        },
      });
    } else if (!item.rssLink) {
      this.router.navigate(["/tabs/listings"], {
        queryParams: {
          t: item.PageName,
          rssLink: `listings://?subcategoryid=${item.SubCategoryID}`,
        },
      });
    } else if (item.rssLink.includes("http")) {
      // falls park(sioux falls custom code)
      // window.open(item.rssLink)
      this.showIframe(item.rssLink);

      // this.router.navigate(['/tabs/listings', this.SIOUX_FALLS_PARK_LISTING_ID],
      // { queryParams: { t: item.SubCategoryName, subCategoryId: item.SubCategoryID } });
    } else {
      this.router.navigate(["/tabs/listings"], {
        queryParams: { t: item.PageName, rssLink: item.rssLink },
      });
    }
  }
  colSize(index) {
    if (this.subcategories.length % 2 && index == this.subcategories.length - 1)
      return "12";
    else return "6";
    //return environment.cms.navigation.itemsPerRowCategory == 1 ? "12" : "6";
  }

  async showIframe(link: string) {
    const modal = await this.modalCtrlr.create({
      component: IFrameModalPage,
      componentProps: {
        url: link,
      },
    });
    await modal.present();
  }
}
